import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import Dashboard from './Pages/UserDashboard';


function AdminPrivateRoute({ handleLogout }) {
    const navigate = useNavigate();
    const [Authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                console.log(localStorage.getItem('auth_token'));
                console.log('Checking authentication...');
                const res = await axios.get('/api/checkingAuthenticated');
                console.log('Response:', res);

                if (res.status === 200) {
                    setAuthenticated(true);
                } else {
                    console.log('Not authenticated');
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
            } finally {
                setLoading(false);
            }
        };

        checkAuth();
    }, []);
    
    axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err){
        if(err.response.status === 401)
        {
            // alert('Please login Or Unauthorized',err.response.data.message,'warning');
            navigate('/login');
        }
        return Promise.reject(err);
    })

    if (loading) {
        return <h5></h5>;
    }

    return Authenticated ? <Dashboard handleLogout={handleLogout}><Outlet /></Dashboard> : <Navigate to="/login" />;
}

export default AdminPrivateRoute;

import React, { useEffect, useState } from "react";
import PageHeader from "../Components/PageHeader";
import CheckoutCardSection from "../Components/CheckoutCardsection";
import CheckoutCard from "../Components/CheckoutCard";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useCart } from "../Components/CartContext";

const CheckoutPage = () => {
  const { cart = [] } = useCart(); // ✅ Fix this
  const [selectedShippingAddress, setSelectedShippingAddress] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [useWallet, setUseWallet] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [checkoutInput, setCheckoutInput] = useState({
    user_id: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch user data to check login status
    axios
      .get("/api/auth/user")
      .then((response) => {
        const userData = response.data.user;
        if (userData) {
          setIsLoggedIn(true); // User is logged in
          setCheckoutInput({
            user_id: userData.user_id || "",
            firstname: userData.firstname || "",
            lastname: userData.lastname || "",
            email: userData.email || "",
            phone: userData.phone || "",
          });
        } else {
          setIsLoggedIn(false); // User is not logged in
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setIsLoggedIn(false); // Treat error as not logged in
      });
  }, []);

  // const cartItems = [
  //   { name: "Gym Coords Set (Brown)", price: 15.0, quantity: 1 },
  //   { name: "Tan Cargo Shorts", price: 12.0, quantity: 1 },
  // ];

  const addresses = [
    { id: "new", title: "New Home", address: "26, Starts Hollow, Denver, CO" },
    {
      id: "old",
      title: "Old Home",
      address: "53B, Claire New St, San Jose, CO",
    },
    { id: "office", title: "Office", address: "21B, Row New St, San Jose, CA" },
  ];

  const paymentMethods = [
    { id: "PAYID", name: "PAYID" },
    { id: "BANK TRANSFER", name: "BANK TRANSFER" },
    { id: "PAYPAL", name: "PAYPAL" },
    { id: "STRIPE", name: "STRIPE" },
  ];

  const deliveryOptions = [
    { id: "standard", title: "Standard Delivery | 5-7 Days" },
    { id: "express", title: "Express Delivery | 1-2 Days" },
  ];
  const renderPaymentDetails = () => {
    console.log("Currently selected payment method:", selectedPayment); // Debugging log

    if (selectedPayment === "PAYID") {
      return (
        <div className="col-12">
          <div className="bg-white p-3 border rounded">
            <h6 className="fw-bold mb-2">PayID Details</h6>
            <p className="m-0">
              <strong>PayID:</strong> 0451112478
            </p>
          </div>
        </div>
      );
    } else if (selectedPayment === "BANK TRANSFER") {
      // ✅ Fix the condition match
      return (
        <div className="col-12">
          <div className="bg-white p-3 border rounded">
            <h6 className="fw-bold mb-2">Bank Transfer Details</h6>
            <p className="m-0">
              <strong>Name:</strong> Account Name
            </p>
            <p className="m-0">
              <strong>BSB:</strong> 062 123
            </p>
            <p className="m-0">
              <strong>ACC:</strong> 1234 5678
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  // const subTotal = cartItems.reduce(
  //   (total, item) => total + item.price * item.quantity,
  //   0
  // );
  const subTotal = cart.reduce(
    (total, item) => total + (item.pro_price * item.quantity || 0),
    0
  );
  const TAX_RATE = 0.0909;
  const tax = cart.reduce(
    (total, item) => total + (item.pro_price * item.quantity * TAX_RATE || 0),
    0
  );
  const holidaySavings = promoCode === "HOLIDAY40" ? 5.0 : 0;

  const points = 65.66;
  const walletBalance = 8.47;
  const total =
    subTotal +
    tax -
    holidaySavings -
    // (usePoints ? points : 0) -
    (useWallet ? walletBalance : 0);

  const orderData = new FormData();

  // Append necessary form fields
  orderData.append("user_id", checkoutInput.user_id);
  orderData.append("shippingaddress", selectedShippingAddress);
  orderData.append("total_price", subTotal);
  orderData.append("payment_method", selectedPayment);

  // Append each item individually
  cart.forEach((item, index) => {
    orderData.append(`items[${index}][id]`, item.product_id); // Ensure this is `product_id`
    orderData.append(`items[${index}][ord_price]`, item.pro_price);
    orderData.append(`items[${index}][ord_quantity]`, item.quantity);
  });

  console.log("Order Payload:", orderData);

  // Function to Place Order
  const handlePlaceOrder = async () => {
    setErrors({}); // Reset errors before validation
    if (!isLoggedIn) {
      alert("User not authenticated. Please log in before placing the order.");
      navigate("/login"); // ✅ Redirect to login page
      return;
    }
    if (!selectedShippingAddress || selectedShippingAddress === "new") {
      alert("Please select a valid shipping address.");
      setErrors((prev) => ({
        ...prev,
        shippingaddress: "Please select a valid shipping address.",
      }));
      return;
    }

    if (!checkoutInput.user_id) {
      alert("User not authenticated. Please log in before placing the order.");
      return;
    }
    if (!selectedPayment) {
      alert("Please select a payment method.");
      return;
    }
    try {
      const response = await axios.post("/api/place-order", orderData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        alert("Order placed successfully!");
        navigate("/order-success");
        // clearCart();
      } else {
        alert("Order failed! Please try again.");
        console.error("Order Failed:", response.data);
      }
    } catch (error) {
      if (error.response?.status === 422) {
        let errorMessages = Object.values(error.response.data.error)
          .flat()
          .join("\n");
        alert("Validation Error:\n" + errorMessages);
      } else {
        alert("An error occurred while placing the order. Please try again.");
        console.error(
          "Order Placement Error:",
          error.response?.data || error.message
        );
      }
    }
  };

  return (
    <>
      <PageHeader title="Checkout" path="Home / Shop / Checkout" />
      <div className="container my-5">
        <div className="row">
          {/* Left Side */}
          <div
            className="col-md-7"
            style={{
              height: "115vh",
              overflowY: "scroll",
              scrollbarWidth: "none",
            }}
          >
            {/* Login Section */}
            {!isLoggedIn && (
              <div className="card border-0 bg-light mb-4">
                <div className="card-body p-4 text-start">
                  <h5 className="fw-bold mb-3">Login</h5>
                  <button className="btn btn-primary rounded-0">
                    <Link
                      to="/login"
                      className="text-decoration-none text-white"
                    >
                      Login
                    </Link>
                  </button>
                </div>
              </div>
            )}

            {/* Conditional Rendering for User Details and Other Sections */}
            {isLoggedIn && (
              <>
                {/* User Details */}
                <div className="card border-0 bg-light mb-4">
                  <div className="card-body p-4 text-start">
                    <h5 className="fw-bold mb-3">User Details</h5>
                    <div className="row g-4">
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <strong>First Name: </strong>{" "}
                          {checkoutInput.firstname}
                        </li>
                        <li className="mb-2">
                          <strong>Last Name: </strong> {checkoutInput.lastname}
                        </li>
                        <li className="mb-2">
                          <strong>Email: </strong> {checkoutInput.email}
                        </li>
                        <li>
                          <strong>Phone Number: </strong> {checkoutInput.phone}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* Shipping Address */}
                <CheckoutCardSection
                  title="Shipping Address"
                  options={addresses}
                  selectedOption={selectedShippingAddress}
                  onSelect={(address) => {
                    setErrors((prev) => ({ ...prev, shippingaddress: "" })); // Clear error first
                    setSelectedShippingAddress(address);
                    console.log("Selected Shipping Address:", address);
                  }}
                />

                {/* Delivery Options */}
                {/* <CheckoutCardSection
                  title="Delivery Options"
                  options={deliveryOptions}
                  selectedOption={selectedDelivery}
                  onSelect={setSelectedDelivery}
                /> */}
              </>
            )}

            {/* Payment Options - Always Visible */}
            <div className="card border-0 bg-light mb-4">
              <div className="card-body p-4">
                <h5 className="fw-bold mb-3">Payment Options</h5>
                <div className="row g-4">
                  {paymentMethods.map((method) => (
                    <CheckoutCard
                      key={method.id}
                      id={method.id}
                      title={method.name}
                      selected={selectedPayment === method.id}
                      onChange={() => {
                        console.log("Selected Payment Method:", method.id);
                        setSelectedPayment(method.id); // ✅ Correctly update selected payment method
                      }}
                    />
                  ))}

                  {/* ✅ Ensure payment details are displayed correctly */}
                  {renderPaymentDetails()}
                </div>
              </div>
            </div>
          </div>

          {/* Right Side - Billing Summary */}
          <div className="col-md-5">
            <div className="card rounded-0 bg-light border">
              <div className="card-header border-bottom p-3">
                <h4 className="mb-2 fw-bold">Summary Order</h4>
                <p className="text-muted m-0">
                  For a better experience, verify your goods and choose your
                  shipping option.
                </p>
              </div>
              <div className="card-body">
                {cart.map((item, index) => (
                  <div
                    className="d-flex justify-content-between align-items-center mb-3"
                    key={index}
                  >
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={`${
                          axios.defaults.baseURL
                        }${item.images[0]?.imgurl.replace(
                          /^.*?public\/storage\//,
                          "public/storage/"
                        )}`}
                        alt={item.productname}
                        className="border p-2"
                        style={{
                          width: "70px",
                          height: "70px",
                          objectFit: "cover",
                        }}
                      />

                      <div
                        className="text-muted"
                        style={{ fontSize: "0.9rem" }}
                      >
                        <div className="fw-bold">{item.productname}</div>
                        <div className="text-muted">{`Quantity: ${item.quantity}`}</div>
                      </div>
                    </div>
                    <span className="fw-bold text-dark">
                      ${(item.pro_price * item.quantity).toFixed(2)}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="card rounded-0 bg-light border mt-3 p-3">
              <h4 className="fw-bold border-bottom pb-3">Billing Summary</h4>

              <div className="mt-4">
                {/* <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">Holiday Savings</span>
                  <span className="fw-bold text-danger">
                    -${holidaySavings.toFixed(2)}
                  </span>
                </div> */}

                <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">Total</span>
                  <span className="fw-bold">${subTotal.toFixed(2)}</span>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">Shipping</span>
                  <span className="fw-bold">Free</span>
                </div>

                <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">Tax (9.09%)</span>
                  <span className="fw-bold">${tax.toFixed(2)}</span>
                </div>

                {/* <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">Points</span>
                  <span className="fw-bold">${points.toFixed(2)}</span>
                </div> */}

                {/* <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">
                    Would you prefer to pay using points?
                  </span>
                  <input
                    type="checkbox"
                    checked={usePoints}
                    onChange={() => setUsePoints(!usePoints)}
                  />
                </div> */}

                {/* <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">Wallet Balance</span>
                  <span className="fw-bold">${walletBalance.toFixed(2)}</span>
                </div> */}

                {/* <div className="d-flex justify-content-between mb-2">
                  <span className="text-muted">
                    Would you prefer to pay using wallet?
                  </span>
                  <input
                    type="checkbox"
                    checked={useWallet}
                    onChange={() => setUseWallet(!useWallet)}
                  />
                </div> */}

                <div className="d-flex justify-content-between mt-3 pt-3 border-top">
                  <span className="fw-bold">Total</span>
                  <span className="fw-bold text-primary">
                    ${total.toFixed(2)}
                  </span>
                </div>
              </div>

              <button className="btn btn-primary mt-3 rounded-0 w-100">
                <Link
                  // to="/order-success"
                  className="text-decoration-none text-white"
                  onClick={handlePlaceOrder}
                >
                  Place Order
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutPage;

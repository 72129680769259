import React from "react";
import PageHeader from "../Components/PageHeader";
import InputField from "../Components/InputField";
import TextAreaField from "../Components/TextAreaField";

const ContactForm = () => {
  const contactDetails = [
    {
      icon: "bi bi-telephone-fill",
      title: "Contact Us",
      text: "+91 123 - 456 - 7890",
    },
    {
      icon: "bi bi-geo-alt-fill",
      title: "Address",
      text: "ABC Complex, Near XYZ, New York",
    },
    {
      icon: "bi bi-envelope-fill",
      title: "Email",
      text: "support@multikart.com",
    },
  ];

  return (
    <>
      <PageHeader title="Contact Us" path="Home / Contact Us" />
      <div className="container">
        <div className="row py-5 justify-content-center">
          <div className="col-md-12">
            <div className="d-flex flex-column flex-lg-row">
              <div className="col-md-5 pe-lg-5">
                <div>
                  <h1 className="mb-3 fw-bold">Get In Touch</h1>
                  <p>
                    We're here to help! Reach out to us with any questions,
                    feedback, or inquiries, and we'll get back to you as soon as
                    possible.
                  </p>
                </div>

                <div className="d-flex flex-column gap-3">
                  {contactDetails.map((item, index) => (
                    <div
                      className="d-flex border bg-light p-3 align-items-center mb-3"
                      key={index}
                    >
                      <div className="bg-white d-flex align-items-center justify-content-center p-3">
                        <i className={`${item.icon} fs-5 text-primary`}></i>
                      </div>
                      <div className="ms-3 text-start">
                        <h5>{item.title}</h5>
                        <p className="mb-0">{item.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-md-7">
                <form className="bg-light p-5 rounded-0 bg-light shadow-sm">
                  <InputField
                    label="Full Name"
                    type="text"
                    id="fullName"
                    placeholder="Full Name"
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <InputField
                        label="Email"
                        type="text"
                        id="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="Phone"
                        type="text"
                        id="phone"
                        placeholder="Enter Your Phone Number"
                      />
                    </div>
                  </div>
                  <InputField
                    label="Subject"
                    type="text"
                    id="subject"
                    placeholder="Subject"
                  />

                  <TextAreaField
                    label="Write Your Message"
                    id="message"
                    rows="4"
                    placeholder="Write Your Message"
                  />

                  <div className="text-start">
                    <button type="submit" className="btn btn-primary rounded-0">
                      Send Your Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div>
        <div className="col-12 ">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.835434509362!2d144.95565141589513!3d-37.81732717975188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577f7843dfd2dfd!2sVictoria%20Market!5e0!3m2!1sen!2sus!4v1614649485613!5m2!1sen!2sus"
            width="100%"
            height="400"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </>
  );
};
export default ContactForm;

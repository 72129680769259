import React, { useEffect, useState } from "react";
import {
  Clients,
  carouselData,
  fetchProducts,
  fetchProductsByItemTypes,
} from "../Constants/Data";
import ProductCard from "../Components/ProductCard";
import axios from "axios";
import PageLoader from "../Components/PageLoader";
const Home = () => {
  const BASE_URL =
    process.env.REACT_APP_API_URL ||
    "https://shop.adroitalarm.com.au/ecommerce-backend-api/";
  const [products, setProducts] = useState([]); // Store API-fetched products
  const [allProducts, setAllProducts] = useState([]); // Store all products
  // const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedItemTypes, setSelectedItemTypes] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [itemTypes, setItemTypes] = useState([]);
  const [selectedItemType, setSelectedItemType] = useState("");
  // Fetch products from API when the component mounts
  // Function to hide the loader
  const hideLoader = () => {
    setLoading(false);
  };
  useEffect(() => {
    const loadProducts = async () => {
      setLoading(true);
      try {
        const data = await fetchProducts();
        console.log("Fetched Products from API:", data); // ✅ Log API Response

        if (Array.isArray(data) && data.length > 0) {
          setAllProducts(data);
          setProducts(data);
        } else {
          console.error("API returned an empty array or invalid format:", data);
          setAllProducts([]);
          setProducts([]);
        }
      } catch (err) {
        console.error("Error fetching products:", err);
        setError(err.message);
        setAllProducts([]);
        setProducts([]);
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, []);

  useEffect(() => {
    const fetchItemTypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/items/itemtypes`
        );
        console.log("Fetched Item Types:", response.data);
        setItemTypes(response.data);
      } catch (error) {
        console.error("Error fetching item types:", error);
      }
    };

    fetchItemTypes();
  }, []);

  useEffect(() => {
    console.log("Selected Item Type:", selectedItemType);
    console.log("All Products Before Filtering:", allProducts);

    if (!selectedItemType) {
      setProducts(Array.isArray(allProducts) ? allProducts : []);
      return;
    }

    const filtered = Array.isArray(allProducts)
      ? allProducts.filter((product) => {
          console.log("Checking product:", product);
          return product.item_type === selectedItemType;
        })
      : [];

    console.log("Filtered Products:", filtered);
    setProducts(filtered);
  }, [selectedItemType, allProducts]);

  return (
    <>
      {loading && <PageLoader hideLoader={hideLoader} />}

      {!loading && (
        <>
          <section className="container-fluid heroCarousel p-0">
            <div
              id="heroCarousel"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {carouselData.map((slide, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={slide.id}
                    data-bs-interval="5000"
                  >
                    <div className="bg-dark text-white py-5">
                      <div className="container py-5">
                        <div className="row align-items-center">
                          <div className="col-md-6 pb-5">
                            <div className="d-flex gap-2">
                              <p className="text-primary">{slide.subtitle}</p>
                              <i className="bi bi-dash-lg text-primary"></i>
                            </div>
                            <h1 className="fw-bold">{slide.title}</h1>
                            <p
                              className="text-secondary"
                              style={{ fontSize: "14px" }}
                            >
                              {slide.description}
                            </p>
                            <a
                              href={slide.buttonLink}
                              className="btn btn-primary rounded-0 mt-3"
                            >
                              {slide.buttonText}
                            </a>
                          </div>
                          <div className="col-md-6 text-center">
                            <img
                              src={slide.imageUrl}
                              className="img-fluid"
                              alt="Smart Watch"
                              style={{ height: "300px", width: "300px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section className="py-5">
            <div className="client-logos bg-dark d-flex overflow-hidden position-relative py-5">
              <div className="logo-wrapper d-flex">
                {Clients.concat(Clients).map((imageSrc, index) => (
                  <img
                    src={imageSrc}
                    alt={`Client Logo ${index + 1}`}
                    key={`client-${index}`}
                    className="client-logo"
                  />
                ))}
              </div>
            </div>
          </section>

          {/* <section className="container products pb-5 justify-content-center text-center">
        <span className="text-primary d-flex justify-content-center text-center text-uppercase">
          new collection
        </span>
        <h2 className="text-dark text-center text-uppercase py-2 d-inline-block position-relative">
          Item type
        </h2>
        <div className="d-flex justify-content-center gap-5 pt-4">
          <span
            className={`text-uppercase cursor-pointer ${
              selectedCategory === "new" ? "text-primary" : "text-dark"
            }`}
            onClick={() => setSelectedCategory("new")}
            style={{ cursor: "pointer" }}
          >
            new arrival
          </span>
          <span
            className={`text-uppercase cursor-pointer ${
              selectedCategory === "featured" ? "text-primary" : "text-dark"
            }`}
            onClick={() => setSelectedCategory("featured")}
            style={{ cursor: "pointer" }}
          >
            featured
          </span>
        </div>
        <div className="row pt-5">
          {filteredProducts.length > 0 ? (
            filteredProducts.slice(0, 4).map((product) => (
              <div key={product.id} className="col-lg-3 col-md-4 col-sm-6 mb-4">
                <ProductCard product={product} />
              </div>
            ))
          ) : (
            <p className="text-muted">No products available</p>
          )}
        </div>
      </section> */}
          <section className="container products pb-5 justify-content-center text-center">
            <h2 className="text-dark text-center text-uppercase py-2 d-inline-block position-relative">
              Item Type
            </h2>
            {/* ✅ Item Type Filter */}
            <div className="d-flex justify-content-center gap-3 pt-4">
              <button
                className={`btn ${
                  selectedItemType === "" ? "btn-primary" : "btn-light"
                }`}
                onClick={() => setSelectedItemType("")} // Reset to no filter
              >
                All
              </button>

              {itemTypes.map((type) => (
                <button
                  key={type}
                  className={`btn ${
                    selectedItemType === type ? "btn-primary" : "btn-light"
                  }`}
                  onClick={() => setSelectedItemType(type)} // Set a single item type
                >
                  {type}
                </button>
              ))}
            </div>
            {/* ✅ Display Products */}
            {/* ✅ Display Products */}
            <div className="row pt-5">
              {Array.isArray(products) && products.length > 0 ? (
                products.map((product) => (
                  <div
                    key={product?.id || Math.random()}
                    className="col-lg-3 col-md-4 col-sm-6 mb-4"
                  >
                    <ProductCard product={product} />
                  </div>
                ))
              ) : (
                <p className="text-muted">No products available</p>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import PageHeader from "../Components/PageHeader";
import Banner from "../Assets/Images/banner.png";
import ProductCard from "../Components/ProductCard";
import { fetchProducts } from "../Constants/Data";
import PageBanner from "../Components/PageBanner";
import axios from "axios";
import PageLoader from "../Components/PageLoader";

const Shop = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("Ascending Order");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [imageLoading, setImageLoading] = useState(true);
  const totalPages = Math.ceil(fetchProducts.length / itemsPerPage);

  const hideLoader = () => {
    setLoading(false);
  };
  const getFilteredProducts = () => {
    if (!Array.isArray(products)) {
      return []; // ✅ If products is not an array, return an empty array
    }

    return products.filter((product) => {
      const name = product.productname ? product.productname.toLowerCase() : "";
      return name.includes(searchQuery.toLowerCase());
    });
  };

  const getSortedProducts = () => {
    let sortedProducts = [...getFilteredProducts()]; // Ensure we don't modify the original array

    if (sortedProducts.length === 0) {
      return sortedProducts;
    }

    if (sortOrder === "Ascending Order") {
      sortedProducts.sort((a, b) =>
        (a.productname || "").localeCompare(b.productname || "")
      );
    } else if (sortOrder === "Descending Order") {
      sortedProducts.sort((a, b) =>
        (b.productname || "").localeCompare(a.productname || "")
      );
    } else if (sortOrder === "Low - High Price") {
      sortedProducts.sort(
        (a, b) => parseFloat(a.pro_price || 0) - parseFloat(b.pro_price || 0)
      );
    } else if (sortOrder === "High - Low Price") {
      sortedProducts.sort(
        (a, b) => parseFloat(b.pro_price || 0) - parseFloat(a.pro_price || 0)
      );
    }
    return sortedProducts;
  };

  const paginatedProducts = getSortedProducts().slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("/api/getcategory");
        setCategories(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setTimeout(hideLoader, 1000); // Faster load time
      }
    };

    fetchCategories();
  }, []);
  useEffect(() => {
    console.log("🔥 Products State Changed:", products);
  }, [products]); // ✅ Logs state updates

  useEffect(() => {
    const loadProducts = async () => {
      setLoading(true);
      try {
        const data = await fetchProducts();
        console.log("Fetched Products:", data); // ✅ Debug API Response

        if (data.length === 0) {
          console.warn("⚠️ No products found, state is empty");
        } else {
          console.log("✅ Setting products state:", data);
        }

        setProducts(data);
      } catch (err) {
        console.error("❌ Error fetching products:", err);
        setProducts([]); // Fallback, but ensure this isn't called wrongly
      } finally {
        setLoading(false);
      }
    };

    loadProducts();
  }, []); // Ensure only runs ONCE

  useEffect(() => {
    const fetchProductsByCategory = async () => {
      setLoading(true);
      try {
        if (selectedCategories.length > 0) {
          const categoryParam = selectedCategories.join(",");
          const response = await axios.get(
            `/api/category/${categoryParam}/products`
          );

          console.log("✅ API Response:", response.data); // ✅ Log API Response

          // ✅ Ensure Correct Image URL Formatting
          const productsWithImages = response.data.map((product) => ({
            ...product,
            imageUrl: product.image
              ? `https://shop.adroitalarm.com.au/storage/${product.image.replace(
                  "public/storage/",
                  ""
                )}`
              : "https://via.placeholder.com/300", // ✅ Default image if missing
          }));

          setProducts(productsWithImages);
        } else {
          setProducts([]);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
        setTimeout(hideLoader, 1000);
      }
    };

    fetchProductsByCategory();
  }, [selectedCategories]);

  // 🛠️ Handle Category Selection (Checkbox)
  const handleCategorySelection = (categoryId) => {
    setSelectedCategories(
      (prevSelected = []) =>
        prevSelected.includes(categoryId)
          ? prevSelected.filter((id) => id !== categoryId) // Unselect category
          : [...prevSelected, categoryId] // Select category
    );
  };

  return (
    <>
      {loading && <PageLoader hideLoader={hideLoader} />} {/* ✅ Show Loader */}
      {!loading && (
        <>
          <section>
            <PageHeader title="Shop" path="Home / Shop" />

            <div className="container py-5">
              <PageBanner src={Banner} alt="Home Page Banner" />

              <div className="bg-light p-2 border mt-3 d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center gap-5">
                <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2 w-100">
                  <button
                    className="btn border bg-white text-secondary rounded-0 w-100 w-md-auto"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    Filter Panel
                  </button>

                  <select
                    className="form-select text-secondary border bg-white rounded-0 w-100 w-md-auto"
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                  >
                    <option>Ascending Order</option>
                    <option>Descending Order</option>
                    <option>Low - High Price</option>
                    <option>High - Low Price</option>
                  </select>

                  <select
                    className="form-select text-secondary border bg-white rounded-0 w-100 w-md-auto"
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(Number(e.target.value))}
                  >
                    <option value="10">10 Products</option>
                    <option value="20">20 Products</option>
                    <option value="50">50 Products</option>
                    <option value="100">100 Products</option>
                  </select>
                </div>

                <div className="input-group w-100 mt-2 mt-md-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button className="btn btn-light border">
                    <i className="bi bi-search"></i>
                  </button>
                </div>
              </div>

              <div
                className={`border bg-white mt-2 p-3 ${
                  isOpen ? "d-block" : "d-none"
                }`}
              >
                <div className="row">
                  {/* Categories */}
                  <div className="col-md-3">
                    <h6 className="fw-bold">Categories</h6>
                    {/* {["Fashion", "Furniture", "Book", "Vegetable", "Bag"].map(
                  (category, index) => (
                    <div key={index} className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={category}
                      />
                      <label className="form-check-label" htmlFor={category}>
                        {category}
                      </label>
                    </div>
                  )
                )} */}
                    {Array.isArray(categories) &&
                      categories.map((category) => (
                        <div
                          key={category.category_id}
                          className="form-check collection-filter-checkbox"
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={category.categoryname}
                            checked={selectedCategories.includes(
                              category.category_id
                            )} // ✅ Fixed
                            onChange={() =>
                              handleCategorySelection(category.category_id)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={category.categoryname}
                          >
                            {category.categoryname}
                          </label>
                        </div>
                      ))}
                  </div>

                  {/* Brand */}
                  <div className="col-md-3">
                    <h6 className="fw-bold">Brand</h6>
                    {[
                      "Couture Edge",
                      "Glamour Gaze",
                      "Urban Chic",
                      "VogueVista",
                      "Velocity Vibe",
                    ].map((brand, index) => (
                      <div key={index} className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={brand}
                        />
                        <label className="form-check-label" htmlFor={brand}>
                          {brand}
                        </label>
                      </div>
                    ))}
                  </div>

                  {/* Colours */}
                  <div className="col-md-3">
                    <h6 className="fw-bold">Colours</h6>
                    {["Blue", "Green", "Red", "Beige", "Black"].map(
                      (color, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={color}
                          />
                          <label className="form-check-label" htmlFor={color}>
                            {color}
                          </label>
                        </div>
                      )
                    )}
                  </div>

                  {/* Rating */}
                  <div className="col-md-3">
                    <h6 className="fw-bold">Rating</h6>
                    {[5, 4, 3, 2, 1].map((star, index) => (
                      <div
                        key={index}
                        className="form-check d-flex align-items-center"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`star-${star}`}
                        />
                        <label
                          className="form-check-label ms-2"
                          htmlFor={`star-${star}`}
                        >
                          {"★".repeat(star)}
                          {"☆".repeat(5 - star)} ({star} Star)
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="row pt-5">
                {paginatedProducts.length > 0 ? (
                  paginatedProducts.map((product) => (
                    <div
                      key={product.id}
                      className="col-lg-3 col-md-4 col-sm-6 mb-4"
                    >
                      <ProductCard product={product} />
                    </div>
                  ))
                ) : (
                  <p className="text-muted text-center">
                    No products available
                  </p>
                )}
              </div>

              <nav>
                <ul className="pagination justify-content-center gap-3">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link rounded-0"
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                    >
                      <i className="bi bi-chevron-left"></i>{" "}
                    </button>
                  </li>

                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (page) => (
                      <li
                        key={page}
                        className={`page-item ${
                          currentPage === page
                            ? "active bg-primary text-white"
                            : ""
                        }`}
                      >
                        <button
                          className={`page-link ${
                            currentPage === page
                              ? "bg-primary text-white border-primary"
                              : ""
                          }`}
                          onClick={() => setCurrentPage(page)}
                        >
                          {page}
                        </button>
                      </li>
                    )
                  )}

                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link rounded-0"
                      onClick={() =>
                        setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                      }
                    >
                      <i className="bi bi-chevron-right"></i>{" "}
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Shop;

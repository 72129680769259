import React, { useState } from "react";
import PageHeader from "../Components/PageHeader";
import { faqData } from "../Constants/Data";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <PageHeader title="Frequently asked question" path="Home / FAQ" />
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="accordion">
              {faqData.map((item, index) => (
                <div className="accordion-item mb-3 border" key={index}>
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button bg-white ${
                        (openIndex === index ? "" : "collapsed",
                        openIndex === index ? "text-primary bg-light" : "")
                      }`}
                      type="button"
                      onClick={() => toggleAccordion(index)}
                      style={{
                        fontWeight: openIndex === index ? "bold" : "normal",
                      }}
                    >
                      {item.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className={`accordion-collapse collapse ${
                      openIndex === index ? "show" : ""
                    }`}
                  >
                    <div className="accordion-body">{item.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;

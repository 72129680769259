import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";
import { useCart } from "./CartContext";
import { Link } from "react-router-dom";
import axios from "axios";
const ProductCard = ({ product }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSize, setSelectedSize] = useState("M");
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useCart();
  const [productDetails, setProductDetails] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  // Ensure images exist before accessing
  const images = product?.images || [];
  const selectedImageDefault =
    images.length > 0 ? images[0]?.imgurl : "default.jpg";
  const [selectedImage, setSelectedImage] = useState(selectedImageDefault);

  const groupedImages = [];
  for (let i = 0; i < images.length; i += 2) {
    groupedImages.push(images.slice(i, i + 2));
  }
  // Function to fetch product details by ID
  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(`/api/products/${product.id}`);
      setProductDetails(response.data);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };
  useEffect(() => {
    if (showModal) {
      fetchProductDetails();
    }
  }, [showModal]);

  const handleAddToCart = () => {
    if (!product) return;

    const price =
      typeof product.price === "string"
        ? parseFloat(product.price.replace(/[^0-9.]/g, ""))
        : product.price || 0;

    const oldPrice =
      typeof product.oldPrice === "string"
        ? parseFloat(product.oldPrice.replace(/[^0-9.]/g, ""))
        : product.oldPrice || 0;

    addToCart(
      {
        ...product,
        price: price,
        oldPrice: oldPrice,
      },
      quantity,
      selectedSize
    );

    setShowModal(false);
  };

  return (
    <>
      <div className="card border-0 shadow-none rounded-0">
        <div className="bg-light product-hover position-relative overflow-hidden">
          <img
            src={
              product.images?.[0]?.imgurl
                ? `${
                    axios.defaults.baseURL
                  }/public/storage/images/${product.images[0].imgurl
                    .split("/")
                    .pop()}`
                : "https://via.placeholder.com/300"
            }
            alt={product.productname || "Product Image"}
            className="img-fluid"
            onError={(e) => (e.target.src = "https://via.placeholder.com/300")} // ✅ Fallback for broken images
          />

          <div className="icon-batch bg-white position-absolute d-flex flex-column p-2">
            <i className="bi bi-cart" onClick={handleAddToCart}></i>
            <i
              className="bi bi-eye cursor-pointer"
              onClick={() => setShowModal(true)}
            ></i>
          </div>
        </div>

        <div className="card-body px-0 text-start">
          <div className="d-flex justify-content-between">
            <div>
              <Link
                to={`/shop/product/${product.id}`}
                className="text-decoration-none text-dark"
              >
                <h6 className="mb-0">{product.productname}</h6>
              </Link>
              <p className="text-muted mb-0" style={{ fontSize: "13px" }}>
                {product.specification}
              </p>
            </div>
            <div>
              <span className="text-warning fs-4 px-1">★★★★☆</span>
              <span className="text-muted small px-1">{product.rating}</span>
            </div>
          </div>
          <p className="py-2">
            <span className="fw-bold text-primary">{product.pro_price}</span>
            <span className="text-muted text-decoration-line-through ms-2">
              {product.oldPrice}
            </span>
            <span className="text-success ms-2">{product.discount}</span>
          </p>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="d-flex flex-column align-items-center">
                  {/* Large Image */}
                  {/* Large Image in Modal */}
                  <img
                    src={`${axios.defaults.baseURL}${selectedImage.replace(
                      /^.*?public\/storage\//,
                      "public/storage/"
                    )}`}
                    alt={product.productname}
                    className="img-fluid mb-3"
                    style={{
                      maxWidth: "400px",
                      maxHeight: "400px",
                      objectFit: "contain",
                    }}
                  />

                  {/* Thumbnail Carousel */}
                  <div
                    id="imageCarousel"
                    className="carousel slide"
                    data-bs-interval="false"
                  >
                    <div className="carousel-inner">
                      {groupedImages.map((group, index) => (
                        <div
                          key={index}
                          className={`carousel-item ${
                            index === 0 ? "active" : ""
                          }`}
                        >
                          <div className="d-flex justify-content-center gap-3">
                            {group.map((img, idx) => (
                              <img
                                key={idx}
                                src={`${
                                  axios.defaults.baseURL
                                }${img.imgurl.replace(
                                  /^.*?public\/storage\//,
                                  "public/storage/"
                                )}`}
                                alt="Thumbnail"
                                className="img-thumbnail rounded-0"
                                style={{
                                  width: "120px",
                                  height: "120px", // Keep aspect ratio
                                  objectFit: "cover",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  setSelectedImage(
                                    `${
                                      axios.defaults.baseURL
                                    }${img.imgurl.replace(
                                      /^.*?public\/storage\//,
                                      "public/storage/"
                                    )}`
                                  )
                                }
                              />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Carousel Controls */}
                    <button
                      className="carousel-control-prev bg-secondary text-dark rounded-circle"
                      style={{ width: "30px", height: "30px", top: "50px" }}
                      type="button"
                      data-bs-target="#imageCarousel"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>

                    <button
                      className="carousel-control-next bg-secondary text-dark rounded-circle"
                      style={{ width: "30px", height: "30px", top: "50px" }}
                      type="button"
                      data-bs-target="#imageCarousel"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-between align-items-start">
                  <h4 className="fw-bold">{product.productname}</h4>
                  <button
                    className="btn-close"
                    onClick={() => setShowModal(false)}
                  ></button>
                </div>

                <p className="py-2">
                  {/* <span className="text-muted text-decoration-line-through">
                    {product.pro_price}
                  </span>
                  <span className="text-success ms-2">{product.discount}</span>{" "} */}
                  <br />
                  <strong className="me-2">
                    Description :{product.pro_description}
                  </strong>
                </p>

                <p className="text-muted">{product.description}</p>

                <div className="d-flex align-items-center my-3">
                  <strong className="me-2">Price :{product.pro_price}</strong>
                  {/* {["S", "M", "L", "XL"].map((size) => (
                    <div className="bg-light gap-2 py-2" key={size}>
                      <button
                        className={`btn mx-1 ${
                          selectedSize === size
                            ? "active rounded-circle text-primary"
                            : ""
                        }`}
                        onClick={() => setSelectedSize(size)}
                      >
                        {size}
                      </button>
                    </div>
                  ))} */}
                </div>

                <div className="d-flex align-items-center my-3">
                  <strong className="text-dark me-2">Quantity:</strong>
                  <div className="border d-flex align-items-center bg-light p-1 gap-2">
                    <button
                      className="btn btn-sm bg-white border-0"
                      onClick={() => setQuantity(Math.max(1, quantity - 1))}
                    >
                      <i className="bi bi-chevron-left"></i>
                    </button>
                    <span className="mx-2">{quantity}</span>
                    <button
                      className="btn btn-sm bg-white border-0"
                      onClick={() => setQuantity(quantity + 1)}
                    >
                      <i className="bi bi-chevron-right"></i>
                    </button>
                  </div>
                </div>

                <div className="d-flex gap-2 py-2">
                  <button
                    className="btn btn-primary rounded-0 flex-grow-1"
                    onClick={handleAddToCart}
                  >
                    <i className="bi bi-cart"></i> Add To Cart
                  </button>
                  {/* <button className="btn btn-primary rounded-0 flex-grow-1">
                    <Link
                      to="/checkout"
                      className="text-decoration-none text-white"
                    >
                      <i className="bi bi-cash-coin me-2"></i>
                      Buy Now
                    </Link>
                  </button> */}
                </div>

                {/* <div className="d-flex justify-content-between gap-3 py-2">
                  <a href="/" className="text-muted text-decoration-none">
                    <i className="bi bi-heart"></i> Add To Wishlist
                  </a>
                  <a href="/" className="text-muted text-decoration-none">
                    <i className="bi bi-arrow-repeat"></i> Add To Compare
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductCard;

import React from "react";
import { Link } from "react-router-dom";
import PageHeader from "../Components/PageHeader";
import { useCart } from "../Components/CartContext";
import axios from "axios";
const Cart = () => {
  const { cart, updateCartQuantity, removeFromCart } = useCart();

  const totalPrice = cart
    .reduce(
      (sum, item) => sum + (parseFloat(item.pro_price) || 0) * item.quantity,
      0
    )
    .toFixed(2);

  return (
    <section>
      <PageHeader title="Cart" path="Home / Cart" />

      <div className="container py-5">
        {cart.length === 0 ? (
          <div className="text-center">
            <h4>Your cart is empty!</h4>
            <Link to="/shop" className="btn btn-primary mt-3">
              Go to Shop
            </Link>
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-hover align-middle cart-table">
                <thead className="border-bottom table-light">
                  <tr className="text-uppercase">
                    <th>Image</th>
                    <th>Product Name</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <img
                          src={`${
                            axios.defaults.baseURL
                          }${item.images[0]?.imgurl.replace(
                            /^.*?public\/storage\//,
                            "public/storage/"
                          )}`}
                          alt={item.productname}
                          className="border p-2"
                          style={{
                            width: "70px",
                            height: "70px",
                            objectFit: "cover",
                          }}
                        />
                      </td>

                      <td className="fw-semibold">{item.productname}</td>

                      <td>
                        <span className="fw-bold text-dark">
                          ${parseFloat(item.pro_price).toFixed(2)}
                        </span>
                        {item.oldPrice && (
                          <>
                            <span className="text-muted text-decoration-line-through ms-2">
                              ${parseFloat(item.oldPrice).toFixed(2)}
                            </span>
                            <br />
                            <small className="text-danger">
                              You Save: $
                              {parseFloat(item.discount || 0).toFixed(2)}
                            </small>
                          </>
                        )}
                      </td>

                      <td>
                        <div className="d-flex align-items-center p-1">
                          <button
                            className="btn btn-light border rounded-0"
                            onClick={() =>
                              updateCartQuantity(item.id, item.selectedSize, -1)
                            }
                          >
                            <i className="bi bi-chevron-left"></i>
                          </button>
                          <span className="mx-3">{item.quantity}</span>
                          <button
                            className="btn btn-light border rounded-0"
                            onClick={() =>
                              updateCartQuantity(item.id, item.selectedSize, 1)
                            }
                          >
                            <i className="bi bi-chevron-right"></i>
                          </button>
                        </div>
                      </td>

                      <td className="fw-bold text-primary">
                        $
                        {(
                          (parseFloat(item.pro_price) || 0) * item.quantity
                        ).toFixed(2)}
                      </td>

                      <td>
                        <button
                          className="btn btn-light border rounded-0"
                          onClick={() => removeFromCart(item.id)}
                        >
                          <i className="bi bi-trash3-fill"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-end align-items-end border-bottom py-3">
              <h5 className="fw-bold">
                Total Price: <span className="text-primary">${totalPrice}</span>
              </h5>
            </div>

            <div className="d-flex justify-content-between mt-4">
              <Link to="/shop" className="btn btn-primary px-4 py-2 rounded-0">
                Continue Shopping
              </Link>
              <Link
                to="/checkout"
                className="btn btn-primary px-4 py-2 rounded-0"
              >
                Check Out
              </Link>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Cart;

import React from "react";
import PageBanner from "../Components/PageBanner";
import Banner from "../Assets/Images/banner.png";
import Profile from "../Assets/Images/profile.jpg";
import PageHeader from "../Components/PageHeader";

const About = () => {
  return (
    <div>
      <PageHeader title="About Us" path="Home / About Us" />
      <div className="container mt-5">
        <PageBanner src={Banner} alt="Home Page Banner" />

        <div className="pt-4">
          <h4 className="fw-bold text-dark">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium
          </h4>
        </div>

        {/* Content */}
        <div className="mb-4">
          <p className="lead" style={{ fontSize: "1rem" }}>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium. On the other hand, we denounce
            with righteous indignation and dislike men who are so beguiled and
            demoralized by the charms of pleasure of the moment, so blinded by
            desire, that they cannot foresee the pain and trouble that are bound
            to ensue; and equal blame belongs to those who fail in their duty
            through weakness of will, which is the same as saying through
            shrinking from toil and pain. These cases are perfectly simple and
            easy to distinguish. In a free hour, when our power of choice is
            untrammelled and when nothing prevents our being able to do what we
            like best, every pleasure is to be welcomed and every pain avoided.
            But in certain circumstances and owing to the claims of duty or the
            obligations of business it will frequently occur that pleasures have
            to be repudiated and annoyances accepted. The wise man therefore
            always holds in these matters to this principle of selection: he
            rejects pleasures to secure other greater pleasures, or else he
            endures pains to avoid worse pains.
          </p>
        </div>
      </div>

      <div className="py-4 bg-light">
        <p className="text-center">Latest Testimonials</p>
        <h2 className="text-center">WHAT PEOPLE SAY</h2>
        <div className="container">
          <div className="row justify-content-center">
            {[1, 2].map((index) => (
              <div
                className="col-md-6 col-sm-12 d-flex justify-content-center"
                key={index}
              >
                <div
                  className="card rounded-0 border-0 bg-light d-flex flex-row align-items-center p-3"
                >
                  <div className="text-center">
                    <img
                      src={Profile}
                      alt="Profile"
                      className="rounded-circle border p-1"
                      style={{
                        width: "120px",
                        height: "120px",
                        objectFit: "cover",
                      }}
                    />
                    <h6 className="fw-bold m-0 text-primary mt-2">
                      Mark Junco
                    </h6>
                    <p className="text-muted">Designer</p>
                  </div>
                  <div className="ms-3 text-start">
                    <p
                      className="card-text"
                      style={{ fontSize: "0.9rem", color: "#555" }}
                    >
                      "you how all this mistaken idea of denouncing pleasure and
                      praising pain was born and I will give you a complete
                      account of the system, and expound the actual teachings."
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row border-top border-bottom py-4">
          <div className="col-md-4 border-end d-flex align-items-center justify-content-center py-2">
            <i className="bi bi-truck px-4 fs-1 text-primary"></i>
            <div>
              <h5 className="fw-bold">Free Shipping</h5>
              <p className="text-muted small">Free Shipping World Wide</p>
            </div>
          </div>

          <div className="col-md-4 border-end d-flex align-items-center justify-content-center py-2">
            <i className="bi bi-clock px-4 fs-1 text-primary"></i>
            <div>
              <h5 className="fw-bold">24 X 7 Service</h5>
              <p className="text-muted small">
                24 Online Service For New Customer
              </p>
            </div>
          </div>

          <div className="col-md-4 d-flex align-items-center justify-content-center py-2">
            <i className="bi bi-megaphone px-4 fs-1 text-primary"></i>
            <div>
              <h5 className="fw-bold">Festival Offer</h5>
              <p className="text-muted small">
                New Online Special Festival Offer
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

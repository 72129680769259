import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    try {
      const savedCart = localStorage.getItem("cart");
      return savedCart ? JSON.parse(savedCart) : [];
    } catch (error) {
      console.error("Error loading cart from localStorage:", error);
      return [];
    }
  });
  const [skipLocalStorageUpdate, setSkipLocalStorageUpdate] = useState(false);

  useEffect(() => {
    if (!skipLocalStorageUpdate) {
      localStorage.setItem("cart", JSON.stringify(cart));
    } else {
      setSkipLocalStorageUpdate(false); // Reset after skipping one update
    }
  }, [cart, skipLocalStorageUpdate]); // ✅ Include both dependencies

  const addToCart = (product, quantity = 1, selectedSize) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (item) => item.id === product.id && item.selectedSize === selectedSize
      );

      if (existingItemIndex !== -1) {
        return prevCart.map((item, index) =>
          index === existingItemIndex
            ? {
                ...item,
                quantity: quantity,
                price: !isNaN(parseFloat(product.pro_price))
                  ? parseFloat(product.price)
                  : 0,
                oldPrice: !isNaN(parseFloat(product.oldPrice))
                  ? parseFloat(product.oldPrice)
                  : 0,
              }
            : item
        );
      } else {
        return [
          ...prevCart,
          {
            ...product,
            price: !isNaN(parseFloat(product.price))
              ? parseFloat(product.price)
              : 0,
            oldPrice: !isNaN(parseFloat(product.oldPrice))
              ? parseFloat(product.oldPrice)
              : 0,
            quantity,
            selectedSize,
          },
        ];
      }
    });
  };

  // const addToCart = async (productId, quantity = 1, selectedSize = "M") => {
  //   try {
  //     // Fetch product details from the API
  //     const response = await fetch(`/products/${productId}`);
  //     const product = await response.json();

  //     setCart((prevCart) => {
  //       const existingItemIndex = prevCart.findIndex(
  //         (item) => item.id === productId && item.selectedSize === selectedSize
  //       );

  //       if (existingItemIndex !== -1) {
  //         // Update quantity for an existing item
  //         return prevCart.map((item, index) =>
  //           index === existingItemIndex
  //             ? { ...item, quantity: item.quantity + quantity }
  //             : item
  //         );
  //       } else {
  //         // Add a new item to the cart
  //         return [
  //           ...prevCart,
  //           {
  //             id: productId,
  //             title: product.productname,
  //             imageUrl: `${product.images[0]?.imgurl}`, // Adjust based on your API structure
  //             price: parseFloat(product.pro_price),
  //             oldPrice: product.oldPrice ? parseFloat(product.oldPrice) : null,
  //             discount: product.discount ? parseFloat(product.discount) : null,
  //             quantity,
  //             selectedSize,
  //           },
  //         ];
  //       }
  //     });
  //   } catch (error) {
  //     console.error("Error adding product to cart:", error);
  //   }
  // };

  const removeFromCart = (id, size) => {
    setCart((prevCart) =>
      prevCart.filter((item) => !(item.id === id && item.selectedSize === size))
    );
  };

  const updateCartQuantity = (id, size, change) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === id && item.selectedSize === size
          ? { ...item, quantity: Math.max(1, item.quantity + change) }
          : item
      )
    );
  };

  const clearCart = () => {
    setSkipLocalStorageUpdate(true); // ✅ Prevent saving empty cart
    setCart([]); // ✅ Clears UI but keeps data in localStorage
  };

  return (
    <CartContext.Provider
      value={{ cart, addToCart, removeFromCart, updateCartQuantity, clearCart }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);

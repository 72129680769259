import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../Assets/Images/image.jpeg";
import { useCart } from "./CartContext";
import axios from "axios";

const Header = ({ isLoggedIn }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [show, setShow] = useState(false);
  const { cart, removeFromCart, updateCartQuantity } = useCart();
  const [navbarFixed, setNavbarFixed] = useState(false);
  const toggleOffcanvas = () => setShow(!show);
  const cartRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 40) {
        setNavbarFixed(true);
      } else {
        setNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [show]);

  return (
    <>
      <Container fluid className="p-0">
        <div className="header d-none d-lg-flex align-items-center justify-content-center bg-dark py-2 transition">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-center text-white">
                <div className="d-flex align-items-center me-auto">
                  <p className="mb-0 me-4" style={{ fontSize: "14px" }}>
                    Welcome to Our Adroit Shop
                  </p>
                  <i className="bi bi-telephone-fill me-2"></i>
                  <a
                    href="tel:1234567890"
                    className="text-decoration-none text-white"
                    style={{ fontSize: "14px" }}
                  >
                    <p className="mb-0">Call Us: 123 - 456 - 7890</p>
                  </a>
                </div>

                <div className="d-flex align-items-center ms-auto gap-3">
                  <i className="bi bi-heart-fill me-3"></i>
                  <div
                    className="dropdown"
                    onMouseEnter={() => setShowDropdown(true)}
                    onMouseLeave={() => setShowDropdown(false)}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="dropdown"
                      aria-expanded={showDropdown}
                    >
                      <i className="bi bi-person-fill me-2"></i>
                      <p className="mb-0" style={{ fontSize: "14px" }}>
                        My Account
                      </p>
                    </div>

                    {/* <ul
                      className={`dropdown-menu ${showDropdown ? "show" : ""}`}
                      style={{ minWidth: "150px" }}
                    >
                      <li>
                        <a className="dropdown-item" href="/login">
                          Login
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/dashboard">
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/user-profile">
                          Edit Profile
                        </a>
                      </li>
                    </ul> */}

                    <ul
                      className={`dropdown-menu ${showDropdown ? "show" : ""}`}
                      style={{ minWidth: "150px" }}
                    >
                      {isLoggedIn ? (
                        <>
                          <li>
                            <a className="dropdown-item" href="/dashboard">
                              Dashboard
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="/user-profile">
                              Edit Profile
                            </a>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <a className="dropdown-item" href="/login">
                              Login
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="/register">
                              Register
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav
          className={`navbar navbar-expand-lg bg-white ${
            navbarFixed ? "fixed-top shadow-sm" : ""
          }`}
        >
          <div className="container">
            <div className="d-flex align-items-center gap-3">
              <span
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasMenu"
                aria-controls="offcanvasMenu"
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-list fs-3"></i>
              </span>

              <a className="navbar-brand d-flex align-items-center" href="/">
                <img
                  src={Logo}
                  alt="Logo"
                  className="me-2"
                  style={{ height: "40px" }}
                />
              </a>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#mobileMenu"
              aria-controls="mobileMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse justify-content-end gap-3"
              id="navbarNav"
            >
              <ul className="navbar-nav gap-3">
                <li className="nav-item fw-bold">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item fw-bold">
                  <a className="nav-link" href="/about">
                    About Us
                  </a>
                </li>
                <li className="nav-item fw-bold">
                  <a className="nav-link" href="/shop">
                    Shop
                  </a>
                </li>
                <li className="nav-item fw-bold">
                  <a className="nav-link" href="/contact">
                    Contact Us
                  </a>
                </li>
                <li className="nav-item fw-bold">
                  <a className="nav-link" href="/faq">
                    FAQ
                  </a>
                </li>
              </ul>
              <div className="d-flex align-items-center gap-3">
                {/* <span>
                  <i className="bi bi-search fs-5"></i>
                </span>
                <span>
                  <i className="bi bi-gear fs-5"></i>
                </span> */}
                <span
                  onClick={toggleOffcanvas}
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  <i className="bi bi-cart fs-5"></i>
                  {cart.length > 0 && (
                    <span
                      className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary"
                      style={{ fontSize: "12px", padding: "4px 6px" }}
                    >
                      {cart.length}
                    </span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </nav>

        {/* Cart */}
        <div
          className={`offcanvas offcanvas-end ${show ? "show" : ""}`}
          ref={cartRef}
          tabIndex="-1"
        >
          <div className="offcanvas-header bg-white border-bottom">
            <h5 className="offcanvas-title">My Cart ({cart.length})</h5>
            <button
              type="button"
              className="btn-close bg-light text-dark"
              id="offcanvasMenuLabel"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={toggleOffcanvas}
            ></button>
          </div>

          <div className="offcanvas-body">
            {/* <div className="py-2 border-bottom">
              <p>Spend $20.96 More And Enjoy Free Shipping!</p>
              <div
                className="progress position-relative mt-3"
                style={{ height: "10px", overflow: "visible" }}
              >
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated bg-primary"
                  role="progressbar"
                  style={{ width: "70%" }}
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>

                <div
                  className="position-absolute"
                  style={{
                    left: "70%",
                    transform: "translate(-50%, -50%)",
                    zIndex: "10",
                  }}
                >
                  <div
                    className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center"
                    style={{
                      width: "25px",
                      height: "25px",
                    }}
                  >
                    <i className="bi bi-truck"></i>
                  </div>
                </div>
              </div>
            </div> */}
            {cart.length === 0 ? (
              <p className="text-center">Your cart is empty.</p>
            ) : (
              cart.map((item, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center border-bottom py-2"
                >
                  <img
                    src={
                      item.images?.length > 0
                        ? `${
                            axios.defaults.baseURL
                          }${item.images[0]?.imgurl.replace(
                            /^.*?public\/storage\//,
                            "public/storage/"
                          )}`
                        : "/placeholder.jpg" // ✅ Fallback image
                    }
                    alt={item.productname}
                    width="50"
                  />

                  <div>
                    <p className="mb-0 fw-bold">{item.productname}</p>
                    <p className="mb-0">
                      Size: {item.selectedSize} | Qty: {item.quantity}
                    </p>
                    <p className="mb-0 text-primary">
                      ${(parseFloat(item.pro_price) * item.quantity).toFixed(2)}
                    </p>
                  </div>

                  <div className="d-flex align-items-center border p-1">
                    <button
                      className="btn btn-sm btn-light border-0 rounded-0"
                      onClick={() =>
                        updateCartQuantity(item.id, item.selectedSize, -1)
                      }
                    >
                      -
                    </button>
                    <span className="mx-2">{item.quantity}</span>
                    <button
                      className="btn btn-sm btn-light border-0 rounded-0"
                      onClick={() =>
                        updateCartQuantity(item.id, item.selectedSize, 1)
                      }
                    >
                      +
                    </button>
                  </div>
                  <button
                    className="btn btn-sm text-danger"
                    onClick={() => removeFromCart(item.id, item.selectedSize)}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              ))
            )}

            <div className="d-flex gap-2 py-3 border-bottom justify-content-between">
              <h5 className="m-0">Sub Total:</h5>
              <span className="text-primary">
                $
                {cart.length > 0
                  ? cart
                      .reduce(
                        (sum, item) =>
                          sum +
                          (parseFloat(item.pro_price) || 0) * item.quantity,
                        0
                      )
                      .toFixed(2)
                  : "0.00"}
              </span>
            </div>

            <div className="d-flex gap-2 py-3">
              <button
                className="btn btn-primary rounded-0 flex-grow-1"
                onClick={() => setShow(false)}
              >
                <Link to="/cart" className="text-white text-decoration-none">
                  View Cart
                </Link>
              </button>
              <button
                className="btn btn-primary rounded-0 flex-grow-1"
                onClick={() => setShow(false)}
              >
                <Link
                  to="/checkout"
                  className="text-white text-decoration-none"
                >
                  Check Out
                </Link>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Offcanvas */}
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="mobileMenu"
          aria-labelledby="mobileMenuLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title text-white" id="mobileMenuLabel">
              Menu
            </h5>
            <button
              type="button"
              className="btn-close bg-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>

          <div className="offcanvas-body d-flex align-items-center justify-content-center">
            <ul className="navbar-nav text-center">
              <li className="nav-item py-4">
                <a className="nav-link fw-bold fs-2" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item py-4 fs-2">
                <a className="nav-link fw-bold" href="/about">
                  About Us
                </a>
              </li>
              <li className="nav-item py-4 fs-2">
                <a className="nav-link fw-bold" href="/shop">
                  Shop
                </a>
              </li>
              <li className="nav-item py-4 fs-2">
                <a className="nav-link fw-bold" href="/contact">
                  Contact Us
                </a>
              </li>
              <li className="nav-item py-4 fs-2">
                <a className="nav-link fw-bold" href="/faq">
                  F & Q
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* sidebar */}
        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasMenu"
          aria-labelledby="offcanvasMenuLabel"
          style={{ maxWidth: "300px" }}
        >
          <div className="offcanvas-header border-bottom bg-white">
            <h5
              className="offcanvas-title fw-bold"
              id="offcanvasMenuLabel"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              style={{ cursor: "pointer" }}
            >
              <i className="bi bi-chevron-left me-2"></i> BACK
            </h5>
          </div>

          <div className="offcanvas-body">
            <ul className="list-unstyled">
              <li className="py-2">
                <a
                  className="text-dark text-decoration-none d-flex justify-content-between"
                  href="#"
                >
                  Clothing <i className="bi bi-chevron-right"></i>
                </a>
              </li>
              <li className="py-2">
                <a
                  className="text-dark text-decoration-none d-flex justify-content-between"
                  href="#"
                >
                  Bags <i className="bi bi-chevron-right"></i>
                </a>
              </li>
              <li className="py-2">
                <a
                  className="text-dark text-decoration-none d-flex justify-content-between"
                  href="#"
                >
                  Footwear <i className="bi bi-chevron-right"></i>
                </a>
              </li>
              <li className="py-2">
                <a
                  className="text-dark text-decoration-none d-flex justify-content-between"
                  href="#"
                >
                  Watches <i className="bi bi-chevron-right"></i>
                </a>
              </li>
              <li className="py-2">
                <a
                  className="text-dark text-decoration-none d-flex justify-content-between"
                  href="#"
                >
                  Accessories <i className="bi bi-chevron-right"></i>
                </a>
              </li>
              <li className="py-2">
                <a
                  className="text-dark text-decoration-none d-flex justify-content-between"
                  href="#"
                >
                  House of Design
                </a>
              </li>
              <li className="py-2">
                <a
                  className="text-dark text-decoration-none d-flex justify-content-between"
                  href="#"
                >
                  Beauty & Personal Care <i className="bi bi-chevron-right"></i>
                </a>
              </li>
              <li className="py-2">
                <a
                  className="text-dark text-decoration-none d-flex justify-content-between"
                  href="#"
                >
                  Home & Decor
                </a>
              </li>
              <li className="py-2">
                <a
                  className="text-dark text-decoration-none d-flex justify-content-between"
                  href="#"
                >
                  Kitchen
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Header;

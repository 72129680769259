import React, { useState } from "react";
import PageHeader from "../Components/PageHeader";
import InputField from "../Components/InputField";
import TextAreaField from "../Components/TextAreaField";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import axios from "axios";
const UserProfile = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");

  const [formData, setFormData] = useState({
    address: "",
    suburb: "",
    postcode: "",
    state: "",
    address_type: "delivery", // Fixed as 'delivery'
  });
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // This prevents page reload
    console.log("Form submitted");
    if (
      !formData.address ||
      !formData.postcode ||
      !formData.state ||
      !formData.suburb
    ) {
      setResponseMessage("Please fill in all required fields.");
      return;
    }
    // Validate Required Fields
    if (
      !formData.address ||
      !formData.postcode ||
      !formData.state ||
      !formData.suburb
    ) {
      setResponseMessage("Please fill in all required fields.");
      return;
    }

    try {
      const response = await axios.post("/api/storeAddress", {
        address: formData.address,
        suburb: formData.suburb,
        postcode: formData.postcode,
        state: formData.state,
        address_type: formData.address_type,
      });

      if (response.data.status === 200) {
        setResponseMessage("Address saved successfully!");
        setFormData({
          address: "",
          suburb: "",
          postcode: "",
          state: "",
          address_type: "delivery",
        });
        setSelectedCountry(null);
        setSelectedState(null);
        setSelectedCity(null);
      } else {
        setResponseMessage(response.data.message || "Failed to save address.");
      }
    } catch (error) {
      setResponseMessage(
        error.response?.data?.message ||
          "An error occurred while saving the address."
      );
    }
  };

  const countryOptions = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
    isoCode: country.isoCode,
  }));

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setSelectedState(null);
    setSelectedCity(null);

    const states = State.getStatesOfCountry(country?.isoCode);
    const formattedStates = states.map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));

    setStateOptions(formattedStates);
    setCityOptions([]);
  };

  const handleStateChange = (state) => {
    setSelectedState(state);
    setSelectedCity(null);

    const cities = City.getCitiesOfState(
      selectedCountry?.isoCode,
      state?.value
    );
    const formattedCities = cities.map((city) => ({
      value: city.name,
      label: city.name,
    }));

    setCityOptions(formattedCities);
  };

  const handleCityChange = (city) => {
    setSelectedCity(city);
    setFormData((prevData) => ({
      ...prevData,
      suburb: city?.label || "",
    }));
  };

  return (
    <>
      <section>
        <PageHeader title="User Profile" path="Home / User Profile" />
        <div className="container py-5 justify-content-center">
          <div className="p-4 border-0 ">
            <h2 className="fw-bold py-2">PERSONAL DETAIL</h2>
            <form>
              <div className="row mb-3">
                <div className="col-md-6">
                  <InputField
                    label="Full Name"
                    type="text"
                    id="fullName"
                    placeholder="Enter Your Full Name"
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Last Name"
                    type="text"
                    id="lastName"
                    placeholder="Enter Your Last Name"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <InputField
                    label="Phone Number"
                    type="text"
                    id="phone"
                    placeholder="Enter Your Phone Number"
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Email"
                    type="text"
                    id="email"
                    placeholder="Enter Your Email"
                  />
                </div>
              </div>

              <div className="mb-3">
                <TextAreaField
                  label="Write Your Message"
                  id="message"
                  rows="4"
                  placeholder="Write Your Message"
                />
              </div>
            </form>
          </div>

          <div className="p-4 border-0 ">
            <h2 className="fw-bold py-2">SHIPPING ADDRESS</h2>
            <form onSubmit={handleSubmit}>
              <div className="row mb-3">
                <div className="col-md-12 mb-3">
                  <label className="form-label">Select Address Type:</label>
                  <Select
                    options={[
                      { value: "new_home", label: "New Home" },
                      { value: "old_home", label: "Old Home" },
                      { value: "office", label: "Office" },
                    ]}
                    placeholder="Select Address Type"
                    className="rounded-0"
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Flat / Street Number"
                    type="text"
                    id="flatPlot"
                    placeholder="Enter your flat or street number"
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Address"
                    type="text"
                    id="address"
                    placeholder="Enter your street address"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <InputField
                    label="Zip Code"
                    type="text"
                    id="zipCode"
                    placeholder="Enter your zip code"
                    value={formData.postcode}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* Country Selection */}
                  <label>Select Country:</label>
                  <Select
                    options={countryOptions}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    placeholder="Select a Country"
                    className="rounded-0"
                  />
                </div>
              </div>

              <div className="row mb-3">
                {/* State Selection */}
                <div className="col-md-6">
                  {stateOptions.length > 0 && (
                    <>
                      <label>Select State:</label>
                      <Select
                        options={stateOptions}
                        value={selectedState}
                        onChange={(state) => {
                          handleStateChange(state);
                          setFormData((prevData) => ({
                            ...prevData,
                            state: state.label,
                          }));
                        }}
                        placeholder="Select a State"
                        className="rounded-0"
                      />
                    </>
                  )}
                </div>

                {/* City Selection */}
                <div className="col-md-6">
                  {cityOptions.length > 0 && (
                    <>
                      <label>Select City:</label>
                      <Select
                        options={cityOptions}
                        value={selectedCity}
                        onChange={handleCityChange}
                        placeholder="Select a City"
                        className="rounded-0"
                      />
                    </>
                  )}
                </div>
              </div>
              <button className="btn btn-primary" type="submit">
                Update
              </button>
              {/* <div className="d-flex align-items-center justify-content-center">
                <button
                  className="btn btn-primary text-white rounded-0"
                  type="submit"
                >
                  Update
                </button>
              </div> */}
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserProfile;

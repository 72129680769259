import React, { useEffect, useState } from "react";
import { useCart } from "../Components/CartContext";
import axios from "axios";
import { useParams } from "react-router-dom";
function OrderSummary() {
  // const { orderId } = useParams(); // Get order ID from URL (if applicable)
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { cart = [] } = useCart(); // ✅ Fix this
  const [orderId, setOrderId] = useState(null);
  const taxRate = 0.0909; // 9.09% tax rate
  const subtotal = cart.reduce(
    (acc, item) => acc + item.pro_price * item.quantity,
    0
  );
  const tax = subtotal * taxRate; // Calculate tax correctly
  const shipping = 12.0; // Fixed shipping cost
  const total = subtotal + shipping + tax;

  useEffect(() => {
    const fetchLatestOrder = async () => {
      try {
        const token = localStorage.getItem("auth_token"); // Get auth token
        const response = await axios.get("/api/latest-order", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.order_id) {
          setOrderId(response.data.order_id);
        }
      } catch (err) {
        console.error("❌ Error fetching latest order:", err);
      }
    };

    fetchLatestOrder();
  }, []);

  useEffect(() => {
    if (!orderId) return;

    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(`/api/get-order/${orderId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.order) {
          setOrderDetails(response.data.order);
        }
      } catch (err) {
        console.error("❌ Error fetching order details:", err);
      }
    };

    fetchOrderDetails();
  }, [orderId]);
  return (
    <>
      <div className="container-fluid bg-light d-flex flex-column align-items-center justify-content-center py-5">
        <div className="d-flex flex-column align-items-center">
          <div>
            <div className="loader d-flex align-items-center justify-content-center">
              <i className="bi bi-check-lg"></i>
            </div>
          </div>
          <h1 className="fw-bold">THANK YOU</h1>
          <p className="text-center mt-2">
            Payment Is Successfully Processed And Your Order Is On The Way
            <br />
            <span className="text-muted">
              Transaction ID: 267676GHERT105467
            </span>{" "}
          </p>
        </div>
      </div>

      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <table className="table border m-0">
              <thead className="table-light">
                <tr className="text-dark text-center">
                  <th>Product</th>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {/* ✅ Dynamically map over cart items */}
                {cart.map((item) => (
                  <tr key={item.id}>
                    <td className="text-center" style={{ padding: "20px 0" }}>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ height: "100%" }}
                      >
                        <img
                          src={`${
                            axios.defaults.baseURL
                          }${item.images[0]?.imgurl.replace(
                            /^.*?public\/storage\//,
                            "public/storage/"
                          )}`}
                          alt={item.productname}
                          className="me-3 border"
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </td>
                    <td className="text-center" style={{ padding: "20px 0" }}>
                      <span style={{ fontSize: "16px" }}>
                        {item.productname}
                      </span>
                    </td>
                    <td
                      className="text-center text-secondary"
                      style={{ fontSize: "16px", padding: "20px 0" }}
                    >
                      {item.quantity}
                    </td>
                    <td
                      className="text-center text-secondary"
                      style={{ fontSize: "16px", padding: "20px 0" }}
                    >
                      ${parseFloat(item.pro_price).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="border border-1 p-3">
              <div className="d-flex justify-content-between py-2">
                <p className="mb-0">Subtotal</p>
                <p className="mb-0">${subtotal.toFixed(2)}</p>
              </div>

              <div className="d-flex justify-content-between py-2">
                <p className="mb-0">Shipping</p>
                <p className="mb-0">${shipping.toFixed(2)}</p>
              </div>
              <div className="d-flex justify-content-between py-2">
                <p className="mb-0">Tax (9.09%)</p>
                <p className="mb-0">${tax.toFixed(2)}</p>
              </div>

              <div className="d-flex justify-content-between fw-bold border-top text-dark pt-3">
                <p>Total</p>
                <p>${total.toFixed(2)}</p>
              </div>
            </div>
          </div>

          {orderDetails?.payment_details ? (
            <div className="col-md-6">
              <div className="card border-0 rounded-0 shadow-sm mb-4">
                <div className="card-body bg-light">
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="text-dark py-2 border-bottom">Summary</h5>
                      <p className="text-secondary mb-2">
                        Order ID: {orderDetails.order_id}
                      </p>
                      <p className="text-secondary mb-2">
                        Order Date: {orderDetails.created_at}
                      </p>
                      <p className="text-secondary mb-4">
                        Order Total: $
                        {orderDetails.total_price
                          ? Number(orderDetails.total_price).toFixed(2)
                          : "0.00"}
                      </p>
                    </div>

                    <div className="col-md-6">
                      <h5 className="text-dark py-2 border-bottom">
                        Shipping Address
                      </h5>
                      {orderDetails.shipping_address ? (
                        <>
                          <p className="mb-2">{orderDetails.shipping_address.address}</p>

                          <p className="mb-2">
                            {orderDetails.shipping_address.suburb},{" "}
                            {orderDetails.shipping_address.zipcode}
                          </p>
                          <p className="mb-4">
                           {orderDetails.shipping_address.postcode}
                          </p>
                        </>
                      ) : (
                        <p className="text-muted">
                          No shipping address available
                        </p>
                      )}
                    </div>

                    <div className="px-2">
                      <h6 className="text-dark py-2 border-bottom">
                        Payment Method
                      </h6>
                      <p className="text-secondary mb-4">
                        {orderDetails.payment_details.payment_method || "N/A"}
                      </p>
                      <p className="text-dark m-0">
                        Expected Date Of Delivery:{" "}
                        <strong>
                          {orderDetails.expected_delivery_date ||
                            "Not Available"}
                        </strong>
                      </p>
                      <a
                        href="#"
                        className="btn btn-link text-decoration-none fw-bold p-0"
                      >
                        Track Order
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-muted text-center">No order details available</p>
          )}
        </div>
      </div>
    </>
  );
}

export default OrderSummary;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { fetchProducts } from "../Constants/Data"; 

const Details = () => {
  const { id } = useParams(); 
  const [activeTab, setActiveTab] = useState("Description"); 

  const product = fetchProducts.find((item) => item.id === Number(id));

  if (!product) {
    return <h2 className="text-center mt-5">Product Not Found</h2>;
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderStars = (rating) => {
    return Array(5)
      .fill(0)
      .map((_, i) => (
        <i
          key={i}
          className={`bi me-1 ${
            i < rating
              ? "bi-star-fill text-warning"
              : "bi-star-fill text-secondary"
          }`}
        ></i>
      ));
  };

  const renderRatingBars = () => {
    const ratingColors = {
      5: "bg-success",
      4: "bg-success",
      3: "bg-success",
      2: "bg-warning",
      1: "bg-danger",
    };

    return [5, 4, 3, 2, 1].map((rating) => (
      <div key={rating} className="d-flex align-items-center mb-2">
        <span className="me-2">{rating}★</span>
        <div className="progress flex-grow-1" style={{ height: "8px" }}>
          <div
            className={`progress-bar ${ratingColors[rating]}`}
            role="progressbar"
            style={{
              width: `${
                (product.ratingCounts[rating] /
                  Math.max(...Object.values(product.ratingCounts))) *
                100
              }%`,
            }}
            aria-valuenow={product.ratingCounts[rating]}
            aria-valuemin="0"
            aria-valuemax={Math.max(...Object.values(product.ratingCounts))}
          ></div>
        </div>
        <span className="ms-2">{product.ratingCounts[rating]}</span>
      </div>
    ));
  };

  return (
    <div className="container p-0 my-5">
      <div className="border">
        <ul
          className="nav nav-tabs border bg-light p-3 gap-3"
          id="myTab"
          role="tablist"
        >
          {["Description", "Review"].map((tab) => (
            <li className="nav-item" role="presentation" key={tab}>
              <button
                className={`nav-link rounded-0 ${
                  activeTab === tab
                    ? "active bg-primary text-white"
                    : "bg-white text-muted"
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>

        <div className="tab-content p-3">
          {/* Description Tab */}
          {activeTab === "Description" && (
            <div>
              <p>{product.description}</p>
              <p>
                <strong>Features:</strong>
                <ul>
                  {product.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </p>
            </div>
          )}

          {/* Reviews Tab */}
          {activeTab === "Review" && (
            <div className="row">
              <div className="col-md-6 border-end p-4">
                <div className="d-flex align-items-center border-bottom mb-3">
                  <span className="fs-1 fw-bold">
                    {product.rating.toFixed(1)}
                  </span>
                  <span className="text-muted ms-3">
                    Based on {product.reviewsCount} Ratings
                  </span>
                  <div className="ms-3">{renderStars(product.rating)}</div>
                </div>
                <div className="mb-4">{renderRatingBars()}</div>
                <button className="btn btn-primary rounded-0 w-100 fw-bold my-5">
                  Write Review
                </button>
              </div>
              <div className="col-md-6 p-4">
                {product.reviews.map((review, index) => (
                  <div key={index} className="border bg-light p-3 mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <span className="fw-bold">{review.name}</span>
                        <span className="text-muted ms-2">{review.date}</span>
                      </div>
                      <div>{renderStars(review.rating)}</div>
                    </div>
                    <p className="mt-2">{review.comment}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Q&A Tab */}
          {/* {activeTab === "Q & A" && (
            <div>
              <div className="d-flex justify-content-between">
                <h5 className="py-2">Have Doubts Regarding This Product?</h5>
                <button className="btn btn-primary btn-sm rounded-0">
                  Post Your Question
                </button>
              </div>
              {product.qaData.map((qa, index) => (
                <div key={index} className="py-2 border-bottom">
                  <p className="fw-bold">
                    Q{index + 1}: {qa.question}
                  </p>
                  <p>
                    A{index + 1}: {qa.answer}
                  </p>
                </div>
              ))}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Details;

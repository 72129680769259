import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../Components/PageHeader";
import axios from "axios";

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // const handleEmailChange = (e) => {
  //   const value = e.target.value;
  //   setEmail(value);

  //   if (!validateEmail(value)) {
  //     setErrors((prev) => ({ ...prev, email: "Invalid email format" }));
  //   } else {
  //     setErrors((prev) => ({ ...prev, email: "" }));
  //   }
  // };

  // const handlePasswordChange = (e) => {
  //   const value = e.target.value;
  //   setPassword(value);

  //   if (value.length < 6) {
  //     setErrors((prev) => ({
  //       ...prev,
  //       password: "Password must be at least 6 characters",
  //     }));
  //   } else {
  //     setErrors((prev) => ({ ...prev, password: "" }));
  //   }
  // };

  const isFormValid = email && password && !errors.email && !errors.password;

  const navigate = useNavigate();
  const [loginInput, setLogin] = useState({
    email: "",
    password: "",
    error_list: {},
  });
  const handleInput = (e) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  };
  const loginSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: loginInput.email,
      password: loginInput.password,
    };

    axios
      .get("/sanctum/csrf-cookie")
      .then((response) => {
        axios
          .post(`/api/login`, data)
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("auth_token", res.data.token);
              console.log(res.data.role);
              const expirationTime = Date.now() + 30 * 60 * 1000;
              localStorage.setItem("auth_token_expiry", expirationTime);
              localStorage.setItem("role", res.data.role);
              localStorage.setItem("user", JSON.stringify(res.data));
              setIsLoggedIn(true);
              // console.log(userData.firstname);
              // alert('Logged Successfully');
              navigate("/dashboard");
            } else if (res.status === 401) {
              alert(
                "Warning",
                "Invalid credentials. Please try again.",
                "warning"
              );
            } else {
              setLogin({ ...loginInput, error_list: res.data.errors || {} });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              console.log("401 Error response:", error.response);
              alert(
                "Warning",
                "Invalid credentials. Please try again.",
                "warning"
              );
            } else {
              console.error("Login error:", error);
              setLogin({
                ...loginInput,
                error_list: error.response?.data?.errors || {},
              });
            }
          });
      })
      .catch((error) => {
        console.error("CSRF token error:", error);
      });
  };
  return (
    <>
      <PageHeader title="Login" path="Home / Login" />
      <div className="container py-5">
        <div className="row py-5">
          <div className="col-md-6">
            <h2 className="mb-4 text-uppercase">LOGIN</h2>
            <div className="p-4 border bg-light shadow-sm">
              <form onSubmit={loginSubmit}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className={`form-control rounded-0 ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    name="email"
                    placeholder="Email"
                    value={loginInput.email}
                    onChange={handleInput}
                  />
                  {/* {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )} */}
                  <span className="text-danger">
                    {loginInput.error_list.email
                      ? loginInput.error_list.email[0]
                      : ""}
                  </span>
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className={`form-control rounded-0 ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    placeholder="Enter your password"
                    value={loginInput.password}
                    onChange={handleInput}
                  />
                  {/* {errors.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )} */}
                  <span className="text-danger">
                    {loginInput.error_list.password
                      ? loginInput.error_list.password[0]
                      : ""}
                  </span>
                </div>

                <button
                  className="btn btn-primary rounded-0 px-4"
                  // disabled={!isFormValid}
                  type="submit"
                >
                  Login
                  {/* <Link
                    to="/"
                    className="text-decoration-none text-white"
                  ></Link> */}
                </button>
              </form>
            </div>
          </div>

          <div className="col-md-6">
            <h2 className="mb-4">NEW CUSTOMER</h2>
            <div className="p-4 border bg-light shadow-sm">
              <h3 className="h5 mb-3">Create an account</h3>
              <p className="text-muted">
                Sign up for a free account at our store. Registration is quick
                and easy. It allows you to be able to order from our shop. To
                start shopping click register.
              </p>
              <button className="btn btn-primary rounded-0">
                <Link
                  to="/register"
                  className="text-decoration-none text-white"
                >
                  Create An Account
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

import React from "react";

const TextAreaField = ({ label, id, rows, placeholder }) => {
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <textarea
        className="form-control rounded-0"
        id={id}
        rows={rows}
        placeholder={placeholder}
      ></textarea>
    </div>
  );
};

export default TextAreaField;

import React from "react";

const InputField = ({ label, type, id, placeholder, value, onChange }) => {
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        type={type}
        className="form-control rounded-0"
        id={id}
        placeholder={placeholder}
        value={value} // Pass the value prop
        onChange={onChange} // Pass the onChange prop
      />
    </div>
  );
};

export default InputField;

import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import PageHeader from "../Components/PageHeader";
import { orders, refunds, wallet, notifications } from "../Constants/Data";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Dashboard = ({ handleLogout }) => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const navigate = useNavigate();
  // const confirmLogout = () => {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Once you log out, you will need to log in again.",
  //     icon: "warning",
  //     buttons: ["Cancel", "Yes, logout"],
  //     dangerMode: true,
  //   }).then((willLogout) => {
  //     if (willLogout) {
  //       handleLogout();
  //       navigate("/");
  //     }
  //   });
  // };
  const confirmLogout = () => {
    const willLogout = window.confirm(
      "Are you sure? Once you log out, you will need to log in again."
    );
    if (willLogout) {
      handleLogout();
      navigate("/");
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case "dashboard":
        return (
          <>
            <h4 className="fw-bold">Hello, MARK JECNO!</h4>
            <p className="text-muted small">
              From your My Account Dashboard you have the ability to view a
              snapshot of your recent account activity and update your account
              information. Select a link below to view or edit information.
            </p>

            <div className="mt-4">
              <h5 className="fw-bold">Account Information</h5>
              <p className="small mb-0">
                <strong>Full Name:</strong> John Due
              </p>
              <p className="small mb-0">
                <strong>Phone:</strong> +1 65558845
              </p>
              <p className="small mb-0">
                <strong>Address:</strong> 26, Starts Hollow Colony Denver,
                Colorado, United States B0014
              </p>
            </div>

            <div className="mt-4">
              <h5 className="fw-bold">Login Details</h5>
              <div className="row">
                <div className="col-md-6">
                  <p className="small mb-0">
                    <strong>Email:</strong> john.customer@example.com
                  </p>
                  <a href="#" className="text-primary small">
                    Edit
                  </a>
                </div>
                <div className="col-md-6">
                  <p className="small mb-0">
                    <strong>Password:</strong> ******
                  </p>
                  <a href="#" className="text-primary small">
                    Edit
                  </a>
                </div>
              </div>
            </div>
          </>
        );

      case "notifications":
        return (
          <>
            <div className="card rounded-0 bg-light border-0">
              <h3>Notifications</h3>
              <ul className="list-unstyled">
                {notifications.map((notification, index) => (
                  <li key={index} className="border p-3 mb-3 bg-white">
                    <div className="d-flex align-items-start">
                      <i
                        className="bi bi-circle-fill me-2"
                        style={{ fontSize: "0.5rem", marginTop: "0.3rem" }}
                      ></i>
                      <div>
                        <p className="mb-1">{notification.message}</p>
                        <p className="text-muted small mb-0">
                          <i className="bi bi-clock me-1"></i>
                          {notification.time}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        );

      case "wallet":
        return (
          <>
            <div className="table-responsive">
              <h4 className="fw-bold py-3">My Wallet</h4>
              <table className="table table-hover text-center">
                <thead className="bg-light">
                  <tr className="text-uppercase">
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Remark</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {wallet.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction.date}</td>
                      <td>${transaction.amount.toFixed(2)}</td>
                      <td>{transaction.remark}</td>
                      <td>
                        <span className="badge bg-danger text-white">
                          {transaction.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );

      case "orders":
        return (
          <>
            <div className="table-responsive">
              <h4 className="fw-bold py-3">My Orders</h4>
              <table className="table table-hover text-center">
                <thead className="bg-light">
                  <tr className="text-uppercase">
                    <th>Order Number</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Payment Status</th>
                    <th>Payment Method</th>
                    <th>Option</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td className="fw-bold">#{order.id}</td>
                      <td>{order.date}</td>
                      <td>${order.amount.toFixed(2)}</td>
                      <td>
                        <span className="badge bg-primary text-white rounded-0">
                          {order.status}
                        </span>
                      </td>
                      <td>{order.method}</td>
                      <td>
                        <i
                          className="bi bi-eye fs-5 text-primary"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );

      case "refund":
        return (
          <>
            <div className="table-responsive">
              <h4 className="fw-bold py-3">Refund</h4>
              <table className="table table-hover text-center">
                <thead className="bg-light">
                  <tr className="text-uppercase">
                    <th>Order</th>
                    <th>Status</th>
                    <th>Reason</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {refunds.map((refund) => (
                    <tr key={refund.id}>
                      <td className="fw-bold">#{refund.id}</td>
                      <td
                        className={`fw-bold ${
                          refund.status === "Rejected"
                            ? "text-danger"
                            : "text-success"
                        }`}
                      >
                        {refund.status}
                      </td>
                      <td>{refund.reason}</td>
                      <td>{refund.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );

      default:
        return <p className="text-muted small">Please select a tab.</p>;
    }
  };

  return (
    <>
      <PageHeader title="Shop" path="Home / DashBoard" />
      <div className="container py-5">
        <div className="row">
          {/* Sidebar */}
          <div className="col-md-3 bg-light border p-0">
            <div className="d-flex align-items-center border-bottom p-3">
              <div
                className="bg-white text-white rounded-circle d-flex align-items-center justify-content-center me-2"
                style={{ width: "50px", height: "50px" }}
              >
                <span className="fw-bold text-dark">J</span>
              </div>
              <div>
                <p className="mb-0 small fw-bold">John Due</p>
                <p className="mb-0 text-muted small">
                  john.customer@example.com
                </p>
              </div>
            </div>

            <ul className="list-group rounded-0">
              <li
                className={`list-group-item border-0 bg-white p-3 border-bottom ${
                  activeTab === "dashboard"
                    ? "active text-primary border-top"
                    : ""
                }`}
                onClick={() => setActiveTab("dashboard")}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-speedometer2 p-2 border me-2 fs-3 text-primary"></i>{" "}
                Dashboard
              </li>
              <li
                className={`list-group-item border-0 bg-white p-3 border-bottom ${
                  activeTab === "notifications"
                    ? "active text-primary border-top"
                    : ""
                }`}
                onClick={() => setActiveTab("notifications")}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-bell p-2 border me-2 fs-4 text-primary"></i>{" "}
                Notifications
              </li>
              <li
                className={`list-group-item border-0 bg-white p-3 border-bottom ${
                  activeTab === "bank" ? "active text-primary border-top" : ""
                }`}
                onClick={() => setActiveTab("wallet")}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-bank p-2 border me-2 fs-4 text-primary"></i>{" "}
                My wallet
              </li>
              <li
                className={`list-group-item border-0 bg-white p-3 border-bottom ${
                  activeTab === "orders" ? "active text-primary border-top" : ""
                }`}
                onClick={() => setActiveTab("orders")}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-bag-check p-2 border me-2 fs-4 text-primary"></i>{" "}
                My Orders
              </li>
              <li
                className={`list-group-item border-0 bg-white p-3 border-bottom ${
                  activeTab === "refund" ? "active text-primary border-top" : ""
                }`}
                onClick={() => setActiveTab("refund")}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-clock-history p-2 border me-2 fs-4 text-primary"></i>{" "}
                Refund History
              </li>
              <li
                className={`list-group-item border-0 bg-white p-3 border-bottom ${
                  activeTab === "logout" ? "active text-primary border-top" : ""
                }`}
                onClick={confirmLogout}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-box-arrow-right p-2 border me-2 fs-4 text-primary"></i>{" "}
                Logout
              </li>
            </ul>

            {/* <div className="d-flex align-items-center p-3">
              <Link>
                <span className="fw-bold text-dark">Logout</span>
              </Link>
            </div> */}
          </div>

          {/* Main Content */}
          <div
            className="col-md-9"
            style={{
              maxHeight: "65vh",
              overflowY: "scroll",
              scrollbarWidth: "none",
            }}
          >
            <div className="mx-3 bg-light border p-3">{renderContent()}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

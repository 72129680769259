import React from "react";
import Logo from "../Assets/Images/image.jpeg";

const Footer = () => {
  return (
    <>
      <div className="footer bg-light">
        <div className="container">
          <div className="row align-items-center pt-4">
            <div className="col-md-6">
              <h5 className="fw-bold">KNOW IT ALL FIRST!</h5>
              <p className="text-muted">
                Never Miss Anything From Multikart By Signing Up To Our
                Newsletter.
              </p>
            </div>

            <div className="col-md-6">
              <div className="input-group gap-3">
                <input
                  type="email"
                  className="form-control rounded-0"
                  placeholder="Enter your email"
                />
                <button className="btn btn-primary rounded-0">Subscribe</button>
              </div>
            </div>
          </div>

          <hr />

          <div className="row py-5">
            <div className="col-lg-3 col-md-6 col-sm-12 pb-3">
              <div className="d-flex align-items-center mb-3">
                <img
                  src={Logo}
                  alt="Logo"
                  style={{ height: "40px", width: "150px" }}
                />
              </div>
              <p className="text-secondary lh-lg">
                Discover the latest fashion trends, explore unique styles, and
                enjoy seamless shopping with our carefully curated exclusive
                collections, designed to elevate your wardrobe.
              </p>
              {/* <div className="social-icons d-flex gap-3">
                <a href="#" className="text-dark">
                  <i className="bi bi-facebook fs-5"></i>
                </a>
                <a href="#" className="text-dark">
                  <i className="bi bi-google fs-5"></i>
                </a>
                <a href="#" className="text-dark">
                  <i className="bi bi-twitter-x fs-5"></i>
                </a>
                <a href="#" className="text-dark">
                  <i className="bi bi-instagram fs-5"></i>
                </a>
                <a href="#" className="text-dark">
                  <i className="bi bi-tiktok fs-5"></i>
                </a>
              </div> */}
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 pb-3">
              <h5 className="text-uppercase mb-3">my account</h5>
              <ul className="list-unstyled text-uppercase lh-lg">
                <li>
                  <a href="#" className="text-secondary text-decoration-none">
                    mens
                  </a>
                </li>
                <li>
                  <a href="#" className="text-secondary text-decoration-none">
                    womens
                  </a>
                </li>
                <li>
                  <a href="#" className="text-secondary text-decoration-none">
                    clothing
                  </a>
                </li>
                <li>
                  <a href="#" className="text-secondary text-decoration-none">
                    accessories
                  </a>
                </li>
                <li>
                  <a href="#" className="text-secondary text-decoration-none">
                    featured
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 pb-3">
              <h5 className="text-uppercase mb-3">why we choose</h5>
              <ul className="list-unstyled text-uppercase lh-lg">
                <li className="text-secondary text-decoration-none">
                  shipping & return
                </li>
                <li className="text-secondary text-decoration-none">
                  secure shopping
                </li>
                <li className="text-secondary text-decoration-none">gallery</li>
                <li className="text-secondary text-decoration-none">
                  affiliates
                </li>
                <li className="text-secondary text-decoration-none">
                  contacts
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <h5 className="text-uppercase mb-3">store information</h5>
              <p className="text-secondary">
                <i className="bi bi-geo-alt-fill me-1"></i> Multikart Demo
                Store, <br /> Demo store India 345-659
              </p>
              <p className="text-secondary">
                <i className="bi bi-envelope-fill me-1"></i>
                <a
                  href="mailto:hello@cleanify.com"
                  className="text-secondary text-decoration-none"
                >
                  {" "}
                  hello@cleanify.com
                </a>
              </p>
              <p className="text-secondary">
                <i className="bi bi-telephone-fill me-1"></i> +88 01234 567 890
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="container border-top py-3">
            <div className="row align-items-center text-center">
              <div className="col-md-6 text-md-start text-center">
                <p className="mb-0">
                  All rights reserved. 2025 &copy; <strong>Adroit Shop</strong>
                </p>
              </div>

              <div className="col-md-6  text-center mt-2 mt-md-0">
              <div className="social-icons d-flex justify-content-end gap-3">
                <a href="#" className="text-dark">
                  <i className="bi bi-facebook fs-5"></i>
                </a>
                <a href="#" className="text-dark">
                  <i className="bi bi-google fs-5"></i>
                </a>
                <a href="#" className="text-dark">
                  <i className="bi bi-twitter-x fs-5"></i>
                </a>
                <a href="#" className="text-dark">
                  <i className="bi bi-instagram fs-5"></i>
                </a>
                <a href="#" className="text-dark">
                  <i className="bi bi-tiktok fs-5"></i>
                </a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../src/Assets/Styles/Style.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Shop from "./Pages/Shop";
import Contact from "./Pages/Contact";
import ViewCart from "./Pages/Cart";
import Checkout from "./Pages/Checkout";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import OrderSummary from "./Pages/OrderSuccess";
import UserProfile from "./Pages/UserProfile";
import { CartProvider } from "./Components/CartContext";
import FAQ from "./Pages/FAQ";
import ProductDetails from "./Pages/ProductDetails";
import Dashboard from "./Pages/UserDashboard";
import ScrollToTop from "./Components/ScrollToTop";
import InvoicePage from "./Pages/Invoice";
import axios from "axios";
import { useEffect, useState } from "react";
import AdminPrivateRoute from "./AdminPrivateRoute";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const expiryTime = localStorage.getItem("auth_token_expiry");

    // Check if token exists and has not expired
    if (token && expiryTime && Date.now() < expiryTime) {
      setIsLoggedIn(true);
    } else {
      // If token is expired or not available, log out
      localStorage.removeItem("auth_token");
      localStorage.removeItem("auth_token_expiry");
      setIsLoggedIn(false);
    }
  }, []);
  const handleLogout = () => {
    axios
      .post("/api/logout")
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.removeItem("auth_token");
          localStorage.removeItem("auth_name");
          localStorage.removeItem("isLoggedIn");
          setIsLoggedIn(false); // Update the state correctly after logout
          // alert("Success", res.data.message, "success");
        } else {
          alert("Error", res.data.message, "error");
        }
      })
      .catch((error) => {
        alert("Error", "Failed to logout. Please try again later.", "error");
        console.error("Error logging out:", error);
      });
  };
  return (
    <CartProvider>
      <Router>
        <div className="App">
          <ScrollToTop />
          <Header isLoggedIn={isLoggedIn} />
          <Routes>
            {!isLoggedIn ? (
              <Route
                path="/login"
                element={<Login setIsLoggedIn={setIsLoggedIn} />}
              />
            ) : (
              <Route path="/login" element={<Navigate to="/dashboard" replace />} />
            )}
            {!isLoggedIn ? (
              <Route path="/register" element={<Register />} />
            ) : (
              <Route path="/register" element={<Navigate to="/" replace />} />
            )}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/cart" element={<ViewCart />} />
            <Route path="/checkout" element={<Checkout />} />
            {/* <Route path="/login" element={<Login />} /> */}
            {/* <Route path="/register" element={<Register />} /> */}
            <Route path="/order-success" element={<OrderSummary />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route
              path="/dashboard"
              element={
                isLoggedIn ? (
                  <AdminPrivateRoute handleLogout={handleLogout} />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/shop/product/:id" element={<ProductDetails />} />
            <Route path="/invoice" element={<InvoicePage />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { fetchProducts } from "../Constants/Data";
import PageHeader from "../Components/PageHeader";
import Details from "../Components/Details";
import { Link } from "react-router-dom";
import { useCart } from "../Components/CartContext";

const ProductDetails = () => {
  const { id } = useParams();
  const [selectedSize, setSelectedSize] = useState("M");
  const [activeTab, setActiveTab] = useState("description");
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useCart(); 

  const product = fetchProducts.find((item) => item.id === Number(id));

  if (!product) {
    return <h2 className="text-center mt-5">Product Not Found</h2>;
  }

  const handleAddToCart = () => {
    addToCart({
      ...product,
      id: product.id,
      price: parseFloat(product.price) || 0,
      selectedSize,
      quantity,
    });
  };

  return (
    <>
      <PageHeader
        title={product.title}
        path={`Home / Products / ${product.title}`}
      />
      <div className="container py-5">
        <div className="row">
          <div className="col-md-4">
            <img
              src={product.imageUrl}
              alt={product.title}
              className="img-fluid border p-2"
            />

            <div className="row mt-2 d-flex justify-content-center">
              {product.additionalImages.map((img, index) => (
                <div
                  key={index}
                  className="col-md-4 d-flex justify-content-center"
                >
                  <img
                    src={img}
                    alt={`Additional Product ${index + 1}`}
                    className="img-fluid border p-2"
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Product Details */}
          <div className="col-md-4">
            <div className="d-flex justify-content-between align-items-start mb-2">
              <div>
                <div>
                  <span className="small text-muted">
                    Selling fast! 4 people have this in their carts.
                  </span>
                </div>
                <h2 className="py-2">{product.subtitle}</h2>
                <div className="d-flex align-items-center">
                  <span className="text-warning fs-4 border-end px-1">
                    ★★★★☆
                  </span>
                  <span className="text-muted small px-1">
                    {product.ratings} Reviews
                  </span>
                </div>
              </div>
            </div>

            <div className="mb-2">
              <span className="fw-bold text-muted py-1">
                MRP: {product.price}
              </span>
              <p className="small text-secondary mb-0">
                {product.discount} | Old Price: {product.oldPrice}
              </p>
            </div>

            <div className="d-flex align-items-center my-3">
              <strong className="me-2">Size :</strong>
              {["S", "M", "L", "XL"].map((size) => (
                <div className="bg-light gap-2 py-2" key={size}>
                  <button
                    className={`btn mx-1 ${
                      selectedSize === size
                        ? "active rounded-circle text-primary"
                        : ""
                    }`}
                    onClick={() => setSelectedSize(size)}
                  >
                    {size}
                  </button>
                </div>
              ))}
            </div>

            <div className="d-flex align-items-center my-3 py-2 border-top border-bottom border-dashed">
              <span className="text-muted d-flex gap-2 px-2">
                <i className="bi bi-truck"></i> Delivery & Return
              </span>
              <span className="text-muted d-flex gap-2 px-2">
                <i className="bi bi-chat-square-text"></i> Ask a Question
              </span>
            </div>

            <div className="accordion py-3" id="productDetailsAccordion">
              <div className="accordion-item mb-4">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseDescription"
                  >
                    Product Description
                  </button>
                </h2>
                <div
                  id="collapseDescription"
                  className="accordion-collapse collapse"
                >
                  <div className="accordion-body fs-6">
                    {product.description}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex gap-2 py-2">
                  <button
                    className="btn btn-primary rounded-0 flex-grow-1"
                    onClick={handleAddToCart}
                  >
                    <i className="bi bi-cart"></i> Add To Cart
                  </button>
                  <button className="btn btn-primary rounded-0 flex-grow-1">
                    <Link to="/checkout" className="text-decoration-none text-white">
                    <i className="bi bi-cash-coin me-2"></i> 
                      Buy Now
                    </Link>
                  </button>
                </div>
          </div>

          {/* Purchase Options */}
          <div className="col-md-4">
            {/* <div className="card border rounded-0">
              <div className="card-body d-flex flex-column align-items-center text-center">
                <div className="mb-4">
                  <span className="font-weight-bold">Colour:</span>
                  <div className="d-flex mt-2 justify-content-center">
                    {product.additionalImages.map((img, index) => (
                      <div
                        key={index}
                        className="border me-2 d-flex align-items-center justify-content-center overflow-hidden"
                        style={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={img}
                          alt={`Product Variant ${index + 1}`}
                          className="img-fluid"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="d-flex justify-content-center bg-light align-items-center p-2 border my-2">
                  <button
                    className="btn bg-white rounded-0"
                    style={{ padding: "5px 13px" }}
                  >
                    <i className="bi bi-chevron-left"></i>
                  </button>
                  <span className="mx-2">1</span>
                  <button
                    className="btn bg-white rounded-0"
                    style={{ padding: "5px 13px" }}
                  >
                    <i className="bi bi-chevron-right"></i>
                  </button>
                </div>

                <div className="d-flex justify-content-between gap-3 py-2">
                  <button className="btn btn-primary text-white rounded-0">
                    Out Of Stock
                  </button>
                  <button className="btn btn-primary text-white rounded-0">
                    Buy Now
                  </button>
                </div>

                <div className="mb-3">
                  <span className="text-muted small">
                    Please Hurry Only 10 Left In Stock
                  </span>
                </div>
                <div
                  className="progress mb-3 w-100 bg-light"
                  style={{ height: "8px" }}
                >
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: "75%" }}
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>

                <div className="d-flex">
                  <div className="d-flex gap-3" style={{ fontSize: "0.8rem" }}>
                    <a href="#" className="text-primary text-decoration-none">
                      <i className="bi bi-heart me-1"></i> Add To Wishlist
                    </a>
                    <a href="#" className="text-primary text-decoration-none">
                      <i className="bi bi-arrow-left-right me-1"></i> Add To
                      Compare
                    </a>
                    <a href="#" className="text-primary text-decoration-none">
                      <i className="bi bi-share me-1"></i> Share
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="accordion" id="productDetailsAccordion">
              <div className="accordion-item border bg-light">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFeatures"
                  >
                    Information
                  </button>
                </h2>
                <div
                  id="collapseFeatures"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body">
                    <ul>
                      {product.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                    <div className="py-2 border-bottom">
                      <h6 className="mb-2">Delivery Details</h6>
                      <p className="mb-1">
                        Your order is likely to reach you within 7 days.
                      </p>
                      <p className="mb-0">Hassle free returns within 7 Days.</p>
                    </div>
                    {/* Guaranteed Safe Checkout */}
                    <div className="py-2 border-bottom">
                      <h6 className="mb-2">Guaranteed Safe Checkout</h6>
                      <div className="d-flex justify-content-center">
                        <img
                          src="https://themes.pixelstrap.com/multikart/assets/images/product-details/payments.png"
                          alt="Payment Methods"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    {/* Secure Checkout */}
                    <div className="py-2">
                      <h6 className="mb-2">Secure Checkout</h6>
                      <div className="d-flex justify-content-center">
                        <img
                          src="https://themes.pixelstrap.com/multikart/assets/images/product-details/secure_payments.png"
                          alt="Secure Payment Methods"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Details />
    </>
  );
};

export default ProductDetails;